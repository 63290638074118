<template>
    <div class="main-page">
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-md-6 d-flex align-items-center justify-content-center vh-100">
                    <div class="bd-highlight d-flex flex-column align-items-center justify-content-center">
                       <div class="bd-highlight mb-5">
                            <img src="@/assets/icon/envelope.png" alt="">
                       </div>
                        <div class="bd-highlight">
                            <p class="text-active fm-poppins fst-normal fw-600 fs-32 lh-48">Konfirmasi</p>
                       </div>
                        <div class="bd-highlight px-5 desc-confirm mb-3">
                            <p class="text-body-light fm-nunito fst-normal fw-normal fs-18 lh-25 px-5 text-center">Kami mengirimkan tautan ke email anda, silahkan konfirmasi untuk melanjutkan</p>
                       </div>
                       <div class="bd-highlight d-flex w-100 px-5 justify-content-center">
                           <button type="submit" class="btn btn-confirm py-3">
                                <p class="text-white fm-poppins fst-normal fw-600 fs-14 lh-21 mb-0">Buka Email</p>
                            </button>
                       </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped>
    .desc-confirm {
        width: 90%;
    }
    .btn-confirm {
        background: #007A98;
        border-radius: 10px;
        width: 80%;
    }
</style>
